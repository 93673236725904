.banner-parent {
    position: fixed;
    padding: 7px;
    text-align: center;
    z-index: 20;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    font-family: Sen,sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #FDFCFF;
}

.banner-negative {
    background: #FF006E;
}

.banner-neutral {
    background: #3A86FF;
}