.round-yellow-circle-bottom-container {
    position: absolute;
    width: 100%;
    bottom: 0;
    overflow: hidden;
}

.round-yellow-circle-bottom {
    position: relative;
    padding: 0;
    left: 50%;
    margin-left: -217.5px;
    width: 435px;
    height: 431px;
    bottom: -111px;
    border-radius: 50%;
    background: rgba(255, 190, 11, 0.28);
}

.round-yellow-circle-center-container {
    position: absolute;
    width: 100%;
    top: 76px;
    overflow: hidden;
}

.round-yellow-circle-center {
    position: relative;
    padding: 0;
    left: 50%;
    margin-left: -257.5px;
    width: 515px;
    height: 515px;
    border-radius: 50%;
    background: rgba(255, 190, 11, 0.28);
}

.round-green-circle-center-container {
    position: absolute;
    width: 100%;
    top: 76px;
    overflow: hidden;
}

.round-green-circle-center {
    position: relative;
    padding: 0;
    left: 50%;
    margin-left: -257.5px;
    width: 515px;
    height: 515px;
    border-radius: 50%;
    background: #F1FFBB;
}