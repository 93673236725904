.selector-container {
    text-align: center;
}

.selection {
    margin-left: 12px;
    margin-right: 12px;

    cursor: pointer;

    font-family: Sen, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
}

.active-selection {
    margin-left: 12px;
    margin-right: 12px;

    cursor: pointer;

    font-family: Sen, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    color: #FDFCFF;

    background: #FF006E;
    border-radius: 20px;

    padding: 5px 8px;
}