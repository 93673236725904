.mid-header {
    font-family: Sen, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;

    color: #000000;
}

.sign-out-button {
    font-family: Sen, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    /* Colors/Orange */
    color: #FB5607;
}

.question-solver-current-input {
    font-family: Sen,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 45px;
    text-align: center;

    color: #000000;
}

.current-question-numbers {
    font-family: Sen,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 38px;
    line-height: 45px;


    color: #000000;
}

.correct-answers-label {
    font-family: Sen, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 23px;
}