.w180 {
    width: 180px;
}

.p-absolute {
    position: absolute;
}


.p-relative {
    position: relative;
}

.mg-0-auto {
    margin: 0 auto;
    display: block;
}

.mg-t-150 {
    margin-top: 150px;
}

.mg-b-141 {
    margin-bottom: 141px;
}

.mg-b-23 {
    margin-bottom: 23px;
}

.mg-b-80 {
    margin-bottom: 80px;
}

.mg-b-325 {
    margin-bottom: 325px;
}

.mg-l-28 {
    margin-left: 28px;
}

.mg-b-415 {
    margin-bottom: 415px;
}

.mg-l-16 {
    margin-left: 16px;
}

.mg-l-40 {
    margin-left: 40px;
}

.mg-b-386 {
    margin-bottom: 386px;
}

.mg-l-14 {
    margin-left: 14px;
}

.mg-b-318 {
    margin-bottom: 318px;
}

.mg-l-86 {
    margin-left: 86px;
}

.mg-t-129 {
    margin-top: 129px;
}

.w-343 {
    width: 343px;
}

.mg-t-177 {
    margin-top: 177px;
}

.l-86 {
    left: 86px;
}

.r-86 {
    right: 86px
}

.w-100-pt {
    width: 100%;
}

.mg-t-16 {
    margin-top: 16px;
}

.mg-t-43 {
    margin-top: 43px;
}

.t-76 {
 top: 76px;
}

.t-20 {
    top: 20px;
}

.mg-l-20 {
    margin-left: 20px;
}

.l-20 {
    left: 20px;
}

.l-0 {
    left: 0px;
}


.r-20 {
    right: 20px;
}

.r-0 {
    right: 0;
}


.f-right {
    float: right;
}

.mg-t-14 {
    margin-top: 14px;
}

.mg-r-8 {
    margin-right: 8px;
}

.cursor-pointer {
    cursor: pointer;
}

.txt-align-center {
    text-align: center;
}

.txt-align-right {
    text-align: right;
}

.mg-t-53 {
    margin-top: 53px;
}

.mg-r-20 {
    margin-right: 20px;
}

.mg-t-22 {
    margin-top: 22px;
}

.mg-t-55 {
    margin-top: 55px;
}

.mg-t-41 {
    margin-top: 41px;
}

.mg-t-108 {
    margin-top: 108px;
}

.mg-t-190 {
    margin-top: 190px;
}

.pad-18 {
    padding: 18px;
}

.pad-10 {
    padding: 10px;
}

.pad-20 {
    padding: 18px;
}

.mg-t-34 {
    margin-top: 34px;
}

.mg-t-20 {
    margin-top: 20px;
}

.grid-container-3-4-60 {
    display: grid;
    justify-content: center;
    grid-template-columns: 90px 90px 90px;
    grid-template-rows: 90px 90px 90px 90px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.b-0 {
    bottom: 0;
}

.b-27 {
    bottom: 27px;
}

.b-45 {
    bottom: 45px;
}

.mx-w-75 {
    max-width: 75px;
}

.mx-w-100 {
    max-width: 100px;
}

.mx-w-125 {
    max-width: 125px;
}

.mg-t-84 {
    margin-top: 84px;
}

.mg-t-12 {
    margin-top: 12px;
}

.txt-align-left {
    text-align: left;
}

.display-inline {
    display: inline;
}

.mx-w-300 {
    max-width: 300px;
}

.border-2-solid {
    border: 2px solid #000000;
}

.mg-t-0 {
    margin-top: 0;
}

.f-left {
    float: left;
}

.mg-t-5 {
    margin-top: 5px;
}

.z-0 {
    z-index: 0;
}
.z-n1 {
    z-index: -1;
}

.z-100 {
    z-index: 100;
}