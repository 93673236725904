.high-scores-table {
    border: 1px solid #FF006E;
    box-sizing: border-box;
    border-radius: 5px;

    font-family: Sen, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: #000000;
}