.black-bordered-input {
    background: #FFFFFF;
    border: 2px solid #000000;
    box-sizing: border-box;
    padding: 17px;
    outline: none;

    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: #000000;

}
.black-bordered-input:active {

}