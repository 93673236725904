.purple-button {
    background: #8338EC;
    padding-top: 8px;
    padding-bottom: 9px;
    border-radius: 5px;
    border: 0;

    font-family: 'M PLUS 1p', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FDFCFF;
}

.large-blue-button {
    justify-content: center;
    align-items: center;
    padding: 12px 23px;
    border: 0;

    font-family: Sen, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
    background: #3A86FF;
    border-radius: 5px;
}

.large-orange-button {
    justify-content: center;
    align-items: center;
    padding: 12px 23px;
    border: 0;

    font-family: Sen, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
    background: #FB5607;
    border-radius: 5px;
}

.large-yellow-button {
    justify-content: center;
    align-items: center;
    padding: 12px 23px;
    border: 0;

    font-family: Sen, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
    background: #FFBE0B;
    border-radius: 5px;
}

.large-glowing-yellow-button {
    border: 3px solid #FFBE0B;
    filter: drop-shadow(0px 4px 4px rgba(255, 190, 11, 0.35));
    border-radius: 15px;
    font-family: Sen, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    background-color: Transparent;
    text-align: center;
    color: #000000;
}

.large-glowing-orange-button {
    border: 3px solid #FB5607;
    filter: drop-shadow(0px 4px 4px rgba(255, 128, 11, 0.33));
    border-radius: 15px;
    font-family: Sen, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    background-color: Transparent;
    text-align: center;
    color: #000000;
}

.large-glowing-pink-button {
    border: 3px solid #FF006E;
    filter: drop-shadow(0px 4px 4px rgba(255, 0, 110, 0.32));
    border-radius: 15px;
    font-family: Sen, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    background-color: Transparent;
    text-align: center;
    color: #000000;
}

.large-glowing-purple-button {
    border: 3px solid #8338EC;
    filter: drop-shadow(0px 4px 4px rgba(131, 56, 236, 0.4));
    border-radius: 15px;
    font-family: Sen, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
    background-color: Transparent;
    text-align: center;
    color: #000000;
}

.quit-button {
    background: #8338EC;
    color: #FDFCFF;
    border: none;

    border-radius: 20px;
    padding: 10px;
    font-size: 10px;
    line-height: 12px;
}

.ok-button {
    background: #3A86FF;
    padding: 20px;
    width: 110px;
    text-align: center;
    touch-action: none;
    border: none;

    color: #FDFCFF;
    border-radius: 20px 0 0 20px;
    font-family: Sen,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
}

.clear-button {
    background: #FF006E;
    padding: 20px;
    text-align: center;
    touch-action: none;
    border: none;
    width: 110px;

    color: #FDFCFF;
    border-radius: 0 20px 20px 0;
    font-family: Sen,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
}

.game-solver-number {
    background-color: Transparent;
    border: 0;
    width: 90px;
    height: 90px;
    font-family: Sen,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height */

    text-align: center;

    color: #000000;
}